<template>
    <div class="is-paddingless conversation-box">
        <nav class="navbar is-white chat-info">
            <div class="content"
                 v-if="chat">
                <h5 class="is-marginless text-center">
                    {{ chat.name }} {{ chat.surname }}
                </h5>
                <p class="text-center">
                    <small>
                        <span v-if="chat.email">
                            {{ chat.email }}
                        </span>
                        <span v-if="chat.email && chat.countryCode && chat.phone">/</span>
                        <span v-if="chat.countryCode && chat.phone">
                            (+{{ chat.countryCode }}) {{ chat.phone }}
                        </span>
                    </small>
                </p>
            </div>
            <div class="content is-flex"
                 style="align-items: center; "
                 v-else>
                <h5 class="is-marginless">👈 Select an Inbound Message</h5>
            </div>
        </nav>

        <section class="chat-body">
            <message-block v-if="chat"
                           v-bind:message="chat">
            </message-block>
        </section>

        <nav class="navbar is-white chat-actions is-flex is-align-items-center">
            <div class="" style="flex: 1">
                <b-input placeholder="💬 Your message"
                         rounded
                         style="margin-right: 7.5px; ">
                </b-input>
            </div>
            <div class="">
                <b-button class="is-block"
                          rounded>
                    🛫Send
                </b-button>
            </div>
        </nav>
    </div>
</template>

<script>
    import MessageBlock from "./MessageBlock"

    export default {
        name: "ConversationBox",
        components: {
            MessageBlock
        },
        props: {
            "chat": {
                type: Object
            }
        },
        data() {
            return {

            };
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .conversation-box {
        display: flex;
        flex-direction: column;

        .navbar {
            display: flex;
            justify-content: center;
            align-content: center;

            &.chat-info {
                min-height: 46px;
                text-align: center;
                border-bottom: solid 1px rgba(235, 235, 235, 1);
            }

            &.chat-actions {
                min-height: 55px;
                border-top: solid 1px rgba(235, 235, 235, 1);
            }
        }

        .chat-body {
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            background-color: rgba(229, 221, 213, 1);
            background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23000000' fill-opacity='0.075' fill-rule='evenodd'/%3E%3C/svg%3E")
        }
    }
</style>
