<template>
    <article class="msg">
        <div class="bubble">
            <div class="content">
                <div class="is-flex">
                    <div class="name">
                        {{ message.email }}
                        <span>
                            <small v-if="message.name">
                                {{ message.name }}
                            </small>
                        </span>
                    </div>
                    <div class="timestamp">
                        {{ message.createdAt | dateFnsFormatter("h:mm a") }}
                    </div>
                </div>

                <div class="message d-flex">
                    <span v-html="message.content"></span>
                </div>
            </div>
            <div class="bubble-arrow"></div>
        </div>
    </article>
</template>

<script>
import linkify from "linkify-it";
import tlds from "tlds";

const linkifyInstance = linkify()
    .tlds(tlds)
    .tlds("onion", true)
    .set(
        {
            "fuzzyIP": true
        }
    );

export default {
    name: "message",
    props: {
        "message": {
            type: Object,
            required: true
        }
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted () {
        if (this.message.content) {
            let matches = linkifyInstance.match(this.message.content)
            if (
                !!matches &&
                Array.isArray(matches)
            ) {
                let addLength = 0
                matches.forEach(
                    match => {
                        this.message.content =
                            `${this.message.content.slice(0, match.index + addLength)}<a target="_blank" href="${match.raw}">${match.raw}</a>${this.message.content.slice(match.lastIndex + addLength)}`
                        addLength += (`<a target="_blank" href="${match.raw}">${match.raw}</a>`.length - match.raw.length)
                    }
                )
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    .bubble {
        max-width: 80%;
        width: auto;
        height: auto;
        display: block;
        background-color: rgba(245, 245, 245, 1);
        border-radius: 5px;
        position: relative;
        margin: 10px 0 10px 25px;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);

        &.alt {
            margin: 10px 25px 10px 0;
            background: rgba(220, 248, 198, 1);
        }

        &.follow {
            margin: 2px 0 3px 25px;
        }

        &.altfollow {
            margin: 2px 25px 3px 0;
            background: rgba(220, 248, 198, 1);
        }

        .content {
            padding: 8px 0 8px 0;
            width: 100%;

            .is-flex {
                align-items: center;
            }

            .name {
                font-weight: 600;
                font-size: 14px;
                color: rgba(52, 152, 219, 1);

                padding: 0 0 0 15px;
                margin: 0 0 4px 0;

                flex: 1;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                    font-weight: normal;
                    color: rgba(179, 179, 179, 1);
                }

                &.alt {
                    color: rgba(46, 204, 81, 1);
                }
            }

            .message {
                font-size: 14px;
                font-weight: 500;
                text-align: left;

                color: rgba(43, 43, 43, 1);

                padding: 0 15px 0 15px;
                white-space: pre-line;

                & > span {
                    width: 100%;
                }
            }

            .timestamp {
                font-size: 11px;
                color: rgba(153, 153, 153, 1);
                text-transform: uppercase;

                padding: 0 15px 0 0;
            }
        }

        .bubble-arrow {
            position: absolute;
            left: -11px;
            top: 0;

            &:after {
                content: "";
                position: absolute;
                border-top: 15px solid rgba(245, 245, 245, 1);;
                border-left: 15px solid transparent;
                border-radius: 4px 0 0 0;
                width: 0;
                height: 0;
            }

            &.alt {
                bottom: 20px;
                left: auto;
                right: 4px;

                &:after {
                    border-top: 15px solid rgba(220, 248, 198, 1);
                    transform: scaleX(-1);
                }
            }
        }
    }
}
</style>
