<template>
    <section class="view inbox">
        <div class="columns">
            <div class="column">
                <nav class="panel">
                    <p class="panel-heading">
                        Inbox
                    </p>
                    <div class="panel-block">
                        <b-input type="search"
                                 icon="magnify"
                                 icon-clickable
                                 rounded
                                 placeholder="Search by keywords"
                                 v-model="searchText">
                        </b-input>
                    </div>
                    <div class="panel-block has-tabs">
                        <div class="tabs is-toggle is-small">
                            <ul>
                                <li v-bind:class="{ 'is-active': customerStatus === 'all' }"
                                    v-on:click="customerStatus = 'all'">
                                    <a>
                                        <span>All</span>
                                    </a>
                                </li>
                                <li v-bind:class="{ 'is-active': customerStatus === 'verified' }"
                                    v-on:click="customerStatus = 'verified'">
                                    <a>
                                        <span>Registered</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="panel-block-wrapper">
                        <!-- <label class="panel-block"
                               v-for="message in messages">
                            <input type="checkbox"
                                   v-bind:value="message._id"
                                   v-model="recipients">
                            <div class="content">
                                <h5 class="is-marginless">{{ message.name }} {{ message.surname }}</h5>
                                <p>{{ message.email }}</p>
                                <p v-line-clamp:20="3">{{ message.content }}</p>
                            </div>
                        </label> -->

                        <a class="panel-block"
                           v-for="message in messages"
                           v-bind:key="message._id"
                           v-bind:class="{ 'is-active': !!selectedChat && message._id === selectedChat._id }"
                           v-on:click="selectedChat = message">
                            <div class="content">
                                <h5 class="is-marginless">{{ message.name }} {{ message.surname }}</h5>
                                <p class="is-marginless message-date">
                                    {{ message.createdAt | dateFnsRelativeFormatter }}
                                </p>
                                <p class="is-marginless">{{ message.email }}</p>
                                <p>{{ message.countryCode }} {{ message.phone }}</p>
                                <p v-line-clamp:20="2">{{ message.content }}</p>
                            </div>
                        </a>

                    </div>

                    <div class="panel-block">
                        <button class="button is-link is-outlined is-fullwidth"
                                v-on:click="selectAll">
                            <span v-show="recipients.length !== messages.length">
                                Select All
                            </span>
                            <span v-show="recipients.length === messages.length">
                                Unselect All
                            </span>
                        </button>
                    </div>
                </nav>
            </div>

            <conversation-box class="column is-two-thirds"
                              v-bind:chat="selectedChat">
            </conversation-box>
        </div>
    </section>
</template>

<script>
    import Fuse from "fuse.js";
    import ToolBar from "../components/layouts/ToolBar";
    import ConversationBox from "../components/Inbox/ConversationBox";

    export default {
        name: "Messaging",
        components: {
            ConversationBox,
            ToolBar
        },
        data() {
            return {
                loading: false,
                isAttachmentModalActive: false,

                customerStatus: "all",
                searchText: "",

                recipients: [],

                selectedChat: null
            }
        },
        computed: {
            messages() {
                return this.$store.state.mall.inbox;
            },

            allowSend() {
                return this.recipients.length > 0 && this.title && this.content
            },

            verifiedCustomers() {
                return this.$store.state.users.users.filter((user) => user.verified)
            }
        },
        methods: {
            selectAll() {
                if (this.recipients.length === this.customers.length) {
                    this.recipients = []
                } else {
                    this.recipients = this.customers.map(
                        customer => customer._id
                    )
                }
            },

            groupMessagesBySenders(messages, field) {
                return messages.reduce(
                    (senders, message) => {
                        if (senders.some(
                            (sender) => sender[field] === message[field]
                        )) {
                            sender.messages.push(message)
                        } else {
                            senders.push(
                                {
                                    [field]: message[field],
                                    messages: [message]
                                }
                            )
                        }
                    }, []
                )
            },

            filteredCustomers() {
                let targets = this.customers
                switch (this.customerStatus) {
                    case "verified": {
                        targets = this.customers.filter(
                            customer => customer.verified
                        )
                        break
                    }

                    case "unverified": {
                        targets = this.customers.filter(
                            customer => !customer.verified
                        )
                        break
                    }
                }

                if (this.searchText) {
                    let fuse = new Fuse(targets, {
                        keys: ["name", "surname", "phone"]
                    })
                    targets = fuse.search(this.searchText)
                }

                return targets
            }
        },
        watch: {
            "images"(newVal, oldVal) {
                if (newVal && newVal[0] instanceof File) {
                    let reader = new FileReader()
                    reader.onload = (e) => {
                        this.imagePreview = e.target.result
                    }
                    reader.readAsDataURL(newVal[0])
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .inbox {
        background-color: rgba(255, 255, 255, 1);
        overflow: visible;

        .columns {
            height: 100%;
            margin: 0;

            & > .column {
                &:first-child {
                    padding: 0;

                    .panel {
                        height: 100%;
                        border-radius: 0;
                        overflow: hidden;

                        & > .panel-heading {
                            border-radius: 0;
                            border-top: 0;
                            padding-top: 7.5px;
                            padding-bottom: 7.5px;
                        }

                        & > .has-tabs {
                            box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .panel-block-wrapper {
                            overflow-y: auto;
                            height: calc(100% - 57px - 40px - 57px - 49px);
                            width: 100%;
                            border-right: 1px solid #dbdbdb;
                            border-bottom: 1px solid #dbdbdb;

                            & > .panel-block {
                                border-right: none;

                                .content {
                                    position: relative;
                                    width: 100%;
                                    margin-left: 15px;

                                    & > .message-date {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                    }
                                }

                                &.is-active {
                                    border-left-width: 5px;
                                    background-color: rgba(250, 250, 250, 1);
                                }

                                &:first-child {
                                    border-top: none;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    overflow-y: auto;
                }
            }
        }
    }
</style>

<style lang="scss">
    .messaging {
        .tab-content {
            height: 100%;
        }
    }
</style>
